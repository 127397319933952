.header {
  position: relative;
  background-image: linear-gradient(180deg, #f8284e 150px, #eff0f4 150px);
}

.nakedHeader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 900;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .headerContent {
    margin-bottom: 10px;
  }
}
